/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block; 
	width: 170px;
	height: 132px;
	vertical-align: middle; 
	background-image: url(../images/logo.png);
	background-size: 100% auto; 
	background-repeat: no-repeat; 
	transition: .3s; 
	font-size: 0; 
	line-height: 0; 
	text-indent: -4004px;
}

/*  Modifier: Logo Grey */

.logo--grey {
	background-image: url(../images/logo-grey.png);
}

/* Tablet Portrait */

@include tablet-portrait {
	.logo {
		width: 121px; 
		height: 102px; 
	}
}

/* Mobile */

@include mobile {
	.logo {
		width: 118px; 
		height: 91px; 
	}
}

/*  Retina Display  */

@include retina {
	.logo {
		background-image: url(../images/logo@2x.png);
	}

	/*  Modifier: Logo Grey */

	.logo--grey {
		background-image: url(../images/logo-grey@2x.png);
	}
} 
