/* ------------------------------------------------------------ *\
	Form Contact
\* ------------------------------------------------------------ */

.form-contact {
	padding-bottom: 81px; 
	
	.gform_fields {
		list-style-type: none; 

		li {
			margin-bottom: 30px; 
			
			&:last-child,
			&:first-child {
				max-width: 100%; 
				flex: 0 0 100%;
			}
		}
	}
	.gform_footer {
		margin-top: -7px; 
	}
	.gform_button {
		display: block; 
		width: 100%; 
		min-width: 130px; 
		height: 46px; 
		bottom: 87px; 
		right: 0; 
		border: 2px solid $orange; 
		border-radius: 3px; 
		font-size: 14px; 
		font-weight: 700;
		letter-spacing: 0.03em;  
		color: $white; 
		text-transform: uppercase; 
		background: $orange; 
		transition: color .3s, background .3s; 

		&:hover {
			color: $orange; 
			background: transparent; 
		}
	}
	
	.gform_fields,
	.ginput_complex {
		display: flex; 
		flex-flow: row wrap; 
		margin: 0 -15px; 

		> * {
			display: block; 
			max-width: 50%; 
			flex: 0 0 50%;
			padding: 0 15px; 
		}
	}

	label,
	.gfield_label,
	.gform_heading {
		display: none; 
	}
}

/* Mobile */

@include mobile {
	.form-contact {
		padding-bottom: 20px; 
		
		.gform_fields {

			li {
				margin: 0; 

				+ li {
					margin: 15px 0 0; 
				}
			}
		}
		.ginput_complex {

			span + span {
				margin-top: 15px; 
			}
		}
		.gform_footer {
			padding-top: 15px; 
			margin-top: 0; 
		}

		.gform_fields,
		.ginput_complex {
			display: block; 
			
			> * {
				max-width: 100%; 
			}
		}
	}
}

.form-styles .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), .form-styles .gform_wrapper textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 24px;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  color: #000;
  background: #eee;
}

.form-styles .gform_wrapper textarea {
  padding: 15px 24px;
}

.form-styles .gform_wrapper .top_label .gfield_label {
  display: none;
}

.form-styles .ginput_container_select select {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 24px !important;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  color: #000;
  background: #eee;
}

.form-styles .show-label label {
  display: block !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.form-styles .gform_footer {
  margin-top: 0 !important;
}

.form-styles .gform_footer .button {
  display: block;
  width: 100% !important;
  min-width: 130px;
  height: 46px;
  bottom: 87px;
  right: 0;
  border: 2px solid #ea622d;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: .03em;
  color: #fff;
  text-transform: uppercase;
  background: #ea622d;
  transition: color .3s,background .3s;
}

.form-styles .gform_footer .button:focus, .form-styles .gform_footer .button:hover {
  color: #ea622d;
  background: transparent;
}

.contact-form-margin {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .contact-form-margin {
	margin-bottom: 0;
  }
}