/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.map-container {
	padding-bottom: 45px; 
}

.map {
	height: 450px; 
}

/* Mobile */

@include mobile {
	.map-container {
		position: relative; 
		padding: 75px 0 0 0;
	}

	.map {
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
	}
}
