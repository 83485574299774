.ico-browser { background: url(../images/sprite/ico-browser.png) no-repeat 0 0; background-size: 100% 100%; width: 56px; height: 62px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-chevron-down { background: url(../images/sprite/ico-chevron-down.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 11px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-circles { background: url(../images/sprite/ico-circles.png) no-repeat 0 0; background-size: 100% 100%; width: 84px; height: 83px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-density { background: url(../images/sprite/ico-density.png) no-repeat 0 0; background-size: 100% 100%; width: 116px; height: 82px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-facebook-blue { background: url(../images/sprite/ico-facebook-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 20px; height: 20px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-facebook { background: url(../images/sprite/ico-facebook.png) no-repeat 0 0; background-size: 100% 100%; width: 20px; height: 20px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-instagram { background: url(../images/sprite/ico-instagram.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 18px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-linkedin-blue { background: url(../images/sprite/ico-linkedin-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 20px; height: 20px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-mountains { background: url(../images/sprite/ico-mountains.png) no-repeat 0 0; background-size: 100% 100%; width: 70px; height: 67px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-phone-alt { background: url(../images/sprite/ico-phone-alt.png) no-repeat 0 0; background-size: 100% 100%; width: 61px; height: 61px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-phone-grey { background: url(../images/sprite/ico-phone-grey.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 22px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-phone { background: url(../images/sprite/ico-phone.png) no-repeat 0 0; background-size: 100% 100%; width: 18px; height: 22px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter-blue { background: url(../images/sprite/ico-twitter-blue.png) no-repeat 0 0; background-size: 100% 100%; width: 21px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-twitter { background: url(../images/sprite/ico-twitter.png) no-repeat 0 0; background-size: 100% 100%; width: 21px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-wheel { background: url(../images/sprite/ico-wheel.png) no-repeat 0 0; background-size: 100% 100%; width: 82px; height: 82px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-youtube { background: url(../images/sprite/ico-youtube.png) no-repeat 0 0; background-size: 100% 100%; width: 36px; height: 16px; display: inline-block; vertical-align: middle; font-size: 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.ico-chevron-down { background: url(../images/sprite/ico-chevron-down@2x.png) no-repeat 0 0; width: 18px; height: 11px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-circles { background: url(../images/sprite/ico-circles@2x.png) no-repeat 0 0; width: 84px; height: 83px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-density { background: url(../images/sprite/ico-density@2x.png) no-repeat 0 0; width: 116px; height: 82px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-facebook-blue { background: url(../images/sprite/ico-facebook-blue@2x.png) no-repeat 0 0; width: 20px; height: 20px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-facebook { background: url(../images/sprite/ico-facebook@2x.png) no-repeat 0 0; width: 20px; height: 20px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-instagram { background: url(../images/sprite/ico-instagram@2x.png) no-repeat 0 0; width: 18px; height: 18px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-linkedin-blue { background: url(../images/sprite/ico-linkedin-blue@2x.png) no-repeat 0 0; width: 20px; height: 20px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-phone-grey { background: url(../images/sprite/ico-phone-grey@2x.png) no-repeat 0 0; width: 18px; height: 22px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-phone { background: url(../images/sprite/ico-phone@2x.png) no-repeat 0 0; width: 18px; height: 22px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter-blue { background: url(../images/sprite/ico-twitter-blue@2x.png) no-repeat 0 0; width: 21px; height: 17px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-twitter { background: url(../images/sprite/ico-twitter@2x.png) no-repeat 0 0; width: 21px; height: 17px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-wheel { background: url(../images/sprite/ico-wheel@2x.png) no-repeat 0 0; width: 82px; height: 82px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }
	.ico-youtube { background: url(../images/sprite/ico-youtube@2x.png) no-repeat 0 0; width: 36px; height: 16px; background-size: 100% 100%; display: inline-block; vertical-align: middle; font-size: 0; }}
