/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

/*  Modifier: Container XS */

.container--xs {
	max-width: 800px; 
}

/*  Modifier: Container MD */

.container--md {
	max-width: 1000px; 
}

/* Tablet Portrait */

@include tablet-portrait {
	/*  Modifier: Container XS */

	.container--xs {
		width: 100%; 
	}

	/*  Modifier: Container MD */

	.container--md {
		width: 100%; 
	}
}

/* Mobile */

@include mobile {
	.container {
		padding: 0 33px; 
	}
}

/* ------------------------------------------------------------ *\
	Container Fluid
\* ------------------------------------------------------------ */

.container-fluid {
	padding: 0 50px; 
}

/* Tablet Portrait */

@include tablet-portrait {
	.container-fluid { 
		padding: 0 20px; 
	}
}

/* Mobile */

@include mobile {
	.container-fluid {
		padding: 0; 
	}
}
