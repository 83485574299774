/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
	height: 46px; 
	padding: 0 20px; 
	font-size: 14px; 
	line-height: 46px; 
	letter-spacing: 0.03em; 
	font-weight: 700; 
	text-transform: uppercase; 
}

/*  Modifier: Btn Orange Transparent */

.btn--orange-transparent {
	border: 2px solid $orange; 
	border-radius: 3px; 
	line-height: 41px; 
	color: $orange;
	transition: color .3s, background .3s;  

	&:hover {
		color: $white; 
		background: $orange; 
	}
}

/*  Modifier: Btn Grey Transparent */

.btn--grey-transparent {
	border: 2px solid #ccc; 
	border-radius: 3px; 
	line-height: 41px; 
	color: #ccc;
	transition: color .3s, background .3s;  

	&:hover {
		color: $white; 
		background: #ccc; 
	}
}
