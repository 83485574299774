/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'DIN';
	src: url('../fonts//DIN-Bold.eot');
	src: url('../fonts//DIN-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts//DIN-Bold.woff') format('woff'),
		 url('../fonts//DIN-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'DIN';
	src: url('../fonts//DIN-Regular.eot');
	src: url('../fonts//DIN-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts//DIN-Regular.woff') format('woff'),
		 url('../fonts//DIN-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DIN';
	src: url('../fonts//DIN-Medium.eot');
	src: url('../fonts//DIN-Medium.eot?#iefix') format('embedded-opentype'),
		 url('../fonts//DIN-Medium.woff') format('woff'),
		 url('../fonts//DIN-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
