/* ------------------------------------------------------------ *\
	Callout
\* ------------------------------------------------------------ */

.callout {
	max-width: 1170px; 
	padding: 40px 0 99px; 
	margin: 0 auto; 
	border-bottom: 1px solid #ddd; 
	font-size: 18px; 
	line-height: 1.56; 
	text-align: center; 

	h2 {
		font-size: 40px; 
		line-height: 1.13; 
		letter-spacing: 0.03em; 
		color: $blue; 
		text-transform: uppercase; 
		position: relative; 
		margin-bottom: 29px;
		z-index: 2;  
	}
	p {
		margin-bottom: 37px; 
	}

	/*  Mixin: Callout & Section Bordered */

	&.section--bordered {
	  border-top: 1px solid #ddd;
	  padding: 100px 0;

	  @media (max-width: 767px) {
		padding: 50px 0;
	  }

		&:before {
			//width: 1170px;
			//margin-left: -585px;
		  	display: none;
		}
	}
}

/* Mobile */

@include mobile {
	.callout {
		padding-top: 0; 

		h2 {
			margin-top: -11px; 
		}
		p {
			margin-bottom: 32px; 
		}
		
		/*  Mixin: Callout & Section Bordered */

		&.section--bordered {

			&:before {
				display: block; 
				width: auto; 
				left: 33px; 
				right: 33px; 
				margin-left: 0; 
			}
		}
	}
}
