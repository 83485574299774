/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */

/*  Breakpoints  */

@mixin media($breakpoint) {
	@media(max-width: $breakpoint){
		@content;
	}
}

@mixin tablet {
	@include media(1024px) {
		@content;
	}
}

@mixin small-desktop {
	@include media($screen-lg) {
		@content;
	}
}

@mixin tablet-portrait {
	@include media($screen-md - 1px) {
		@content;
	}
}

@mixin mobile {
	@include media($screen-sm - 1px) {
		@content;
	}
}

@mixin retina {
	@media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min-device-pixel-ratio: 2 ), only screen and ( min-resolution: 192dpi ) {
		@content;
	}
}

/*  Clearfix  */

@mixin cf {
	content: ''; 
	display: table; 
	line-height: 0; 
	clear: both; 
}
