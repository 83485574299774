/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	font-weight: 500; 
	letter-spacing: 0.05em; 
	color: $white; 
	text-transform: uppercase; 

	.nav__group {

		> ul {
			display: flex; 
			margin: 0; 
			list-style-type: none; 
			
			> li {
				transition: transform .3s; 

				&:last-child {
					position: absolute; 
					top: 52px; 
					right: 50px; 			
				}
				&.active {

					> a {
						color: $orange; 
					}
				}

				+ li {
					margin-left: 49px; 
				}
				
				> a {

					&:not([class]) {
						background: none !important; 
						transition: color .3s; 
						
						&:active,
						&:focus {
							color: inherit; 
							text-decoration: none; 
						}
						&:hover {
							color: $orange; 
							text-decoration: none; 
							background: none; 
						}
					}
				}
			}
		}
	}

	.open {

		.nav-dropdown {
			transform: scale(1); 
			opacity: 1; 
			visibility: visible; 
		}
	}
	.nav__phone {
		position: absolute; 
		top: 58px; 
		right: 217px; 
		transition: transform .3s; 

		span {
			display: inline-block; 
			padding-left: 9px; 
			margin-left: 5px; 
			vertical-align: middle; 
			border-left: 1px solid rgba($white, .7); 
		}
	}
}

/*  Modifier: Nav Grey */

.nav--grey {
	color: #888; 

	.nav__phone {

		span {
			border-color: #c3c3c3; 
		}
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	.nav {

		.nav__group {

			> ul {

				> li {

					&:last-child {
						right: 20px; 
					}
				}
			}
		}

		.nav__phone {
			right: 162px; 
		}
	}
}

/* Mobile */

@include mobile {
	.nav {
		position: absolute; 
		width: 100%; 
		height: 100vh; 
		top: 0; 
		left: 0; 
		padding: 0; 
		overflow: hidden; 
		overflow-y: auto; 
		opacity: 0; 
		visibility: hidden; 
		font-size: 25px; 
		letter-spacing: 0.05em; 
		background: $blue; 
		transition: opacity .3s, visibility .3s; 

		&:before {
			content: '';
			position: fixed; 
			width: 100%; 
			height: 105px; 
			bottom: 0; 
			left: 0;  
			opacity: 0; 
			visibility: hidden; 
			background: $blue; 
			z-index: 2; 
		}
		&.nav-visible {
			opacity: 1; 
			visibility: visible; 

			&:before {
				opacity: 1; 
				visibility: visible; 
				transition: opacity .3s .3s, visibility .3s .3s; 
			}
		}

		.nav__group {
			position: static;  
			min-height: 450px; 
			padding: 122px 32px 105px; 

			> ul {
				display: block; 
				border-top: 1px solid #35657a; 

				> li {
					border-bottom: 1px solid #35657a; 

					&:last-child {
						position: static; 
					}

					+ li {
						margin: 0; 
					}

					> a {
						position: relative; 
						display: block; 
						padding: 6px 0 8px; 
						background: none !important; 

						&:not([class]) {
							transition: color .3s, background .3s; 
						}

						&[aria-expanded] {

							&:after {
								transform: rotate(90deg); 
							}

							&:before,
							&:after {
								content: ''; 
								position: absolute; 
								width: 12px; 
								height: 2px; 
								top: 50%; 
								right: 19px; 
								margin-top: -1px; 
								background: $orange; 
								transition: transform .3s; 
							}
						}

						&:not([class]):hover {
							color: $white;
							background: none;
						}

						&[aria-expanded="true"]:hover,
						&[aria-expanded="true"]:focus,
						&[aria-expanded="true"] {
							color: $blue; 
							background: $white !important; 

							&:before {
								transform: rotate(135deg); 
							}
							&:after {
								transform: rotate(45deg); 
							}
						}
					}
				}
			}
		}
		.btn {
			display: block; 
			height: auto; 
			padding: 6px 0 8px;  
			border: 0; 
			font-size: 25px; 
			line-height: 1.59; 
			font-weight: 500;
			letter-spacing: 0.05em;  
			color: $white; 
		}
		.nav__phone {
			position: fixed; 
			height: 45px; 
			top: auto;
			bottom: 30px; 
			right: 32px; 
			left: 32px; 
			border-radius: 3px; 
			font-size: 17px; 
			line-height: 45px; 
			background: #ee571c; 
			z-index: 3; 

			span {
				display: inline; 
				padding: 0; 
				margin: 0; 
				border-left: 0; 
				vertical-align: initial; 

				&:before {
					content: 'Call '; 
				}
			}
		}
		.open {
			
			.nav-dropdown {
				max-height: 1000px; 
				padding: 23px 0 27px; 
			}
			.nav__inner {
				animation: none; 
			}
		}
	}

	/*  Modifier: Nav Grey */

	.nav--grey {
		color: $white; 
	}
}

/* ------------------------------------------------------------ *\
	Nav Dropdown
\* ------------------------------------------------------------ */

.nav-dropdown {
	position: fixed; 
	width: 100%; 
	height: 100%; 
	top: 0; 
	left: 0; 
	transform: scale(.9); 
	opacity: 0; 
	visibility: hidden; 
	font-size: 16px; 
	font-weight: 400; 
	letter-spacing: 0; 
	text-transform: none; 
	text-align: center; 
	background: rgba($blue, .95); 
	transition: transform .3s, opacity .3s, visibility .3s; 
	z-index: 5; 

	ul {
		list-style-type: none; 
	}
	li {
		flex: 0 0 100%;

		&:not(:first-child) {
			
			figure {
				opacity: 0; 
				visibility: hidden; 
				transition: opacity .3s, visibility .3s; 
			}
		}

		ul {
			
			li {

				+ li {
					margin-top: 48px; 

					a {

						&:before {
							display: none; 
						}
					}
				}
			}
		}

		+ li {
			margin-top: 69px; 

			a {

				&:before {
					content: ''; 
					position: absolute; 
					width: 60px; 
					height: 1px; 
					top: -33px; 
					left: 50%; 
					margin-left: -30px; 
					background: #447285; 
				}
			}
		}
	}

	h3 {
		font-weight: 500; 
		text-transform: uppercase;
		margin-bottom: 7px;
		transition: color .3s;
	}
  	p {
	  transition: color .3s;
	}
	a {
		position: relative;
		display: inline-block;
		vertical-align: middle;

		&:hover, &.active {
			color: inherit;
			text-decoration: none;

			h3, p {
				color: $orange;
			}

			//+ figure {
			//	opacity: 1;
			//	visibility: visible;
			//}
		}
	}

	figure {
		position: absolute; 
		width: 100%; 
		height: 100%; 
		min-height: 100vh; 
		top: 50%; 
		left: 100%; 
		transform: translateY(-50%); 
		background-size: cover; 
		background-position: center center; 
	}
	
	.nav__inner {
		height: 100%; 
		flex: 0 0 100%;
		overflow-x: hidden; 
		overflow-y: auto; 
		font-size: 0; 
		white-space: nowrap; 
		text-align: left; 
		-webkit-overflow-scrolling: touch;

		&:before {
			content: ''; 
			display: inline-block; 
			width: 0; 
			height: 100%; 
			vertical-align: middle; 
		}

		> ul {
			position: relative; 
			display: inline-block; 
			width: 100%; 
			max-width: 50%; 
			padding: 100px 0; 
			vertical-align: middle; 
			font-size: 16px; 
			white-space: normal; 
			text-align: center; 
		}
	}
	.nav__close {
		position: absolute; 
		width: 40px; 
		height: 40px; 
		top: 26px; 
		left: 50%; 
		margin-left: -20px; 
		cursor: pointer; 
		z-index: 2; 
		transition: transform .3s; 

		&:before {
			left: 0; 
			border: 2px solid $white; 
			border-right: 0; 
		}
		&:after {
			right: 0; 
			border: 2px solid $blue; 
			border-left: 0; 
		}
		&:hover {
		  transform: scale(1.2);

		  &:before {
			border: 2px solid #ea622d;
			border-right: 0;
		  }
		  &:after {
			border: 2px solid #ea622d;
			border-left: 0;
		  }

			span {
			  &:before,
			  &:after {
				background: rgb(234,98,45);
				background: -moz-linear-gradient(left, rgba(234,98,45,1) 0%, rgba(234,98,45,1) 50%, rgba(234,98,45,1) 50%, rgba(234,98,45,1) 50%, rgba(234,98,45,1) 100%);
				background: -webkit-linear-gradient(left, rgba(234,98,45,1) 0%,rgba(234,98,45,1) 50%,rgba(234,98,45,1) 50%,rgba(234,98,45,1) 50%,rgba(234,98,45,1) 100%);
				background: linear-gradient(to right, rgba(234,98,45,1) 0%,rgba(234,98,45,1) 50%,rgba(234,98,45,1) 50%,rgba(234,98,45,1) 50%,rgba(234,98,45,1) 100%);
			  }
			}
		}

		&:before, 
		&:after {
			content: ''; 
			position: absolute; 
			width: 50%; 
			height: 100%; 
			top: 0; 
		}

		span {

			&:before {
				transform: rotate(45deg); 
			}
			&:after {
				transform: rotate(-45deg); 
			}

			&:before,
			&:after {
				content: ''; 
				position: absolute; 
				width: 20px; 
				height: 2px; 
				top: 50%; 
				margin: -1px 0 0 -10px; 
				left: 50%; 
				background: rgb(255,255,255);
				background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(0,68,98,1) 50%, rgba(0,68,98,1) 50%, rgba(0,68,98,1) 100%);
				background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(0,68,98,1) 50%,rgba(0,68,98,1) 50%,rgba(0,68,98,1) 100%);
				background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(0,68,98,1) 50%,rgba(0,68,98,1) 50%,rgba(0,68,98,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#004462',GradientType=1 );
			}
		}
	}
}

@media screen and (min-width:0\0) {
	.nav-dropdown {

		figure {
			margin-top: -50vh; 
			transform: translateY(0); 
		}
	}
}

/*  Tablet  */

@media(max-width: 1024px){
	.nav-dropdown {
		
		.nav__inner {
			animation: none; 
		}
	}
}

/* Mobile */

@include mobile {
	.nav-dropdown {
		position: static; 
		display: block; 
		height: auto; 
		max-height: 0; 
		overflow: hidden; 
		transform: scale(1); 
		opacity: 1; 
		visibility: visible; 
		transition: max-height .3s, padding .3s; 

		li {

			+ li {
				margin-top: 48px; 

				a {

					&:before {
						display: none; 
					}
				}
			}
		}
		a {
			font-size: 14px; 
		}
		h3 {
			margin-bottom: 5px; 
		}

		.nav__inner {
			height: auto; 
			overflow: visible; 
			transform: none;
			opacity: 1; 
			visibility: visible; 

			&:before {
				display: none; 
			}

			> ul {
				display: block; 
				width: 100%; 
				max-width: 100%; 
				padding: 0; 
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Nav Btn
\* ------------------------------------------------------------ */

.nav-btn {
	position: relative; 
	display: block; 
	width: 25px; 
	height: 9px; 

	&:before {
		top: 0; 
	}
	&:after {
		top: 100%; 
		margin-top: -2px; 
	}

	&.nav-visible {

		&:before {
			transform: rotate(45deg); 
			background: $white; 
		}
		&:after {
			transform: rotate(-45deg); 
			background: $white; 
		}
		
		&:before,
		&:after {
			top: 4px; 
			margin: 0; 
			transition: top .3s, margin .3s, transform .3s .3s, background .3s; 
		}
	}

	&:before,
	&:after {
		content: ''; 
		position: absolute; 
		width: 100%; 
		height: 2px; 
		left: 0; 
		background: $white; 
		transition: top .3s, margin .3s, transform .3s, background .3s; 
	}
}

/*  Modifier: Nav Btn Grey */

.nav-btn--grey {
	
	&:not(.nav-visible) {

		&:before,
		&:after {
			background: #888; 
		}
	}
}

.nav-toggle {
  &:hover, &:focus {
	color: #ea622d;
	text-decoration: none;
	background: none;
	transition: color .3s;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .nav .nav__group > ul > li {
	font-size: 13px;
  }
}

@media (min-width: 768px) {
  .nav__group {
	li.open {
	  a {
		&:hover, &.active {
		  + figure {
			opacity: 1;
			visibility: visible;
		  }
		}
	  }
	}
  }
}