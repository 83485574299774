/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	position: relative; 
	display: flex; 
	height: 700px; 
	align-items: center;
	overflow: hidden; 
	color: $white;  
	text-align: center; 
	text-transform: uppercase; 
	background-size: cover; 
	background-position: center center; 

	&:before {
		content: ''; 
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		background: rgba($black, .5); 
		z-index: 2; 
	}

	h1 {
		margin-bottom: 23px; 
		text-transform: uppercase; 

		&:only-child {
			margin: 0; 
		}
	}

	.animate {

		h4 {
			transition-delay: .2s; 
		}

		> * {
			transform: translateY(120px); 
			opacity: 0; 
			transition: transform .5s, opacity .5s; 
		}

		&.animated {

			> * {
				transform: translateY(0); 
				opacity: 1; 
			}
		}
	}
	.container {
		position: relative; 
		flex: 0 0 100%;
		z-index: 3; 
	}
	.intro__inner {
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		z-index: 1; 
		background-size: cover; 
		background-position: center center; 

		video {
			position: relative; 
			display: block; 
			min-width: 100vw; 
			min-height: 100vh; 
			top: 50%; 
			left: 50%; 
			transform: translate(-50%, -50%); 
		}
	}
	.intro__link {
		position: absolute; 
		width: 50px; 
		height: 50px; 
		bottom: 35px; 
		left: 50%; 
		margin-left: -25px; 
		border-radius: 50%; 
		border: 2px solid rgba($white, .3);
		transition: border-color .3s;  
		z-index: 2; 

		&:hover {
			border-color: $white; 
		}

		i {
			position: absolute; 
			top: 50%; 
			left: 50%; 
			transform: translate(-50%, -35%); 
		}
	}
}

/*  Modifier: Intro Alt */

.intro--alt {

	&:before {
		background: rgba($black, .3); 
	}
}

/* Mobile */

@include mobile {
	.intro {
		height: 450px; 
		padding: 0 32px;

	  	.container {
		  padding-left: 0;
		  padding-right: 0;
		}
	
		h1 {
			line-height: 1.15; 
			margin-bottom: 24px; 
		}
		h4 {
			margin: 0 -10px; 
			line-height: 1.28; 
			letter-spacing: 0.1em; 
		}
		//br {
		//	display: none;
		//}
	}
}
