/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.features {
	list-style-type: none; 

	&:after {
		@include cf; 
	}

	.feature {
		float: left; 
		width: 62.53%;
	}
	.feature--small {
		float: right; 
		width: 37.47%; 
	}
	.feature--1of2 {
		width: 50%; 
	}
}

.feature {
	position: relative; 
	overflow: hidden; 
	text-align: center; 
	background: #f5f5f5; 

	h3 {
		color: $blue; 
		text-transform: uppercase; 
		margin-bottom: 5px; 
	}

	a {
		position: relative; 
		display: block; 
		padding-top: 35%; 

		&:before {
			content: '';
			position: absolute; 
			width: 100%; 
			height: 100%; 
			top: 0;
			left: 0;
			opacity: 0; 
			visibility: hidden; 
			background: rgba($white, .8); 
			z-index: 1; 
			transition: opacity .3s, visibility .3s; 
		}

	  &:focus, &:hover {
		color: inherit;
	  }

		&:hover {
			
			&:before {
				opacity: 1; 
				visibility: visible; 
			}

			.feature__image {

				img {
					transform: scale(1.1); 
				}
			}
			.feature__icon {
				transform: scale(1); 
				opacity: 1; 
				visibility: visible; 
			}
		}
	}
	
	.feature__image {
		position: absolute; 
		top: 0; 
		left: 0; 
		background-repeat: no-repeat; 

		img {
			display: block; 
			width: 100%; 
			height: auto; 
			transition: transform .3s; 
		}
	}
	.feature__image--alt {
		max-width: 75%; 
		left: auto; 
		right: 0; 
	}
	.feature__icon {
		position: absolute; 
		top: 50%; 
		left: 50%; 
		margin: -40px 0 0 -40px; 
		transform: scale(.5); 
		opacity: 0; 
		visibility: hidden; 
		z-index: 2; 
		transition: transform .3s, opacity .3s, visibility .3s; 
	}
	.feature__content {
		position: absolute; 
		top: 50%; 
		right: 0; 
		padding-right: 84px; 
		margin-top: 16px; 
		transform: translateY(-50%); 
		z-index: 2; 
	}
}

/*  Modifier: Feature Big */

.feature--big {
	background: #eee; 

	a {
		padding-top: 70%; 
	}

	.feature__content {
		padding-right: 65px; 
		margin-top: 4px; 
	}
	.feature__image {
		height: 100%; 

		img {
			width: auto; 
			height: 100%; 
		}
	}
}

/*  Modifier: Feature Small */

.feature--small {
	background: $white; 

	a {
		padding-top: 117%; 
	}

	.feature__image {
		width: 100%; 
		top: auto;
		right: 0; 
		bottom: 15%; 
		transform: translateX(0); 

		img {
			width: 100%; 
			height: auto; 
		}
	}
	.feature__content {
		width: 100%; 
		top: auto;
		bottom: 0; 
		padding: 0 0 12px; 
		transform: translateY(0); 
	}
}

/*  Modifier: FEature Small Alt */

.feature--small-alt {
	background: #f5f5f5; 

	a {
		padding-top: 58.2%; 
	}

	.feature__image {
		bottom: 0; 
	}
}

/*  Modifier: Feature Alt */

.feature--alt {
	 background: $white; 
}

/*  Modifier: Feature 1of2 */

.feature--1of2 {

	a {
		padding-top: 75%; 
	}

	.feature__image {
		right: 0; 
		transform: translateX(0); 
	}
	.feature__content {
		left: 0; 
		right: auto; 
		padding: 0 0 0 43px; 
		margin-top: 4px; 
	}
}

/*  Mixin: Feature 1of2 & Feature Alt*/

.feature--1of2 {

	&.feature--alt {

		.feature__content {
			width: 100%; 
			top: auto;
			left: 0; 
			bottom: 0; 
			padding: 0 0 14px; 
			transform: translateY(0); 
		}
	}
}

/* Tablet */

@include tablet {
	.feature {

		a {

			&:before {
				opacity: 1;
				visibility: visible; 
			}
		}

		.feature__content {
			position: static; 
			width: 100%; 
			padding: 0 20px; 
			transform: translate(0, 0); 
		}
		.feature__icon {
			position: static; 
			margin: 0; 
			transform: scale(1); 
			opacity: 1; 
			visibility: visible; 
		}
		.feature__group {
			position: absolute; 
			width: 100%; 
			top: 50%; 
			left: 0; 
			transform: translateY(-50%); 
			z-index: 3; 
		}

		&.feature--alt {

			.feature__content {
				width: 100%; 
				transform: translate(0, 0); 
			}
		}
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	.features {

		.feature {
			float: none; 
			width: 100%; 
		}
	}

	.feature {

		.feature__image {
			height: 100%; 
		}
	}

	/*  Modifier: Feature Small */

	.feature--small {

		a {
			padding-top: 80%; 
		}
	}

	/*  Modifier: Feature Small Alt */

	.feature--small-alt {

		a {
			padding-top: 45%; 
		}
	}

	/*  Modifier: Feature Alt */

	.feature--alt {

		a {
			padding-top: 58%; 
		}
	}
}

/* Mobile */

@include mobile {
	.features {
		margin-bottom: -15px; 

		.feature {

			+ .feature {
				margin-top: 35px; 
			}
		}
	}

	.feature {
		padding: 0 33px; 
		background: none; 
	
		h3 {
			margin: 0 -15px 5px; 
		}
		a {
			padding-top: 0; 

			&:hover {
				text-decoration: none; 
			}
			&:before {
				display: none; 
			}
		}

		.feature__content {
			position: static; 
			padding: 0; 
			margin: 0; 
			transform: none;
		}
		.feature__image {
			position: static; 
			width: 100%; 
			padding-top: 88vw; 
			margin: 0 0 23px; 
			background-size: cover; 
			background-position: center center; 

			img {
				display: none; 
			}
		}
		.feature__image--alt {
			max-width: 100%; 
		}
		.feature__icon {
			display: none; 
		}
		.feature__group {
			position: static; 
			transform: translateY(0); 
		}
	}
}

.section-logos {
	padding: 90px 0;

  .section__head {
	margin-bottom: 40px;
  }

  	@media (max-width: 767px) {
	  padding: 40px 0;
	}
}

.logos {
  div {
	width: 20%;
	float: left;
	text-align: center;

	@media (max-width: 767px) {
	  width: 100%;
	}

	img {
	  height: auto;
	  max-width: 100%;
	}
  }
}
