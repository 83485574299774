/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header { 
	position: fixed; 
	width: 100%; 
	top: 0; 
	left: 0; 
	padding: 60px 0 20px; 
	z-index: 4; 
	transition: padding .3s, background .3s; 
	
	.header__logo {
		position: absolute; 
		top: 24px; 
		left: 50%; 
		transform: translateX(-50%); 
	}
}

/*  Modifier: Header Compact */

.header--compact {
	padding: 30px 0 33px; 
	background: $blue; 

	.nav {
		color: $white; 

		.nav__phone {

			i {
				background-image: url(../images/sprite/ico-phone.png);
			}
		}

		&__group > ul > li:last-child,
		&__phone {
			transform: translateY(-30px); 
		}
	}
	.header__logo {
		width: 150px; 
		height: 60px; 
		top: 19px; 
	}
}

/*  Modifier: Header Brick */

.header--brick { 
	top: -10000px; 
	right: -10000px; 
}

/* Tablet Portrait */

@include tablet-portrait {
	.header {
		padding: 60px 0 20px; 
	}

	/*  Modifier: Header Compact */

	.header--compact {
		padding: 30px 0 33px; 

		.header__logo {
			width: 120px; 
			height: 45px; 
			top: 25px; 
		}
	}
}

/* Mobile */

@include mobile {
	.header {
		text-align: center; 
		padding: 20px 0; 

		&.open {

			.nav {
				opacity: 1; 
				visibility: visible; 
			}
		}

		.header__logo {
			position: static; 
			transform: translateY(0); 
		}
		.nav-btn {
			position: absolute; 
			top: 41px; 
			right: 32px; 
			z-index: 8; 
			transition: top .3s; 
		}
	}

	/*  Modifier: Header Compact */

	.header--compact {
		padding: 18px 0 17px; 

		.nav {

			&__group > ul > li:last-child,
			&__phone {
				transform: translateY(0); 
			}
		}
		.nav-btn {
			top: 36px; 

			&:not(.nav-visible) {
				&:before,
				&:after {
					background: $white; 
				}
			}
		}
	}
}
