/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list"] {
	list-style-type: none; 
}

/* ------------------------------------------------------------ *\
	List Clients
\* ------------------------------------------------------------ */

.list-clients {
	display: flex; 
	flex-flow: row wrap; 
	align-items: center; 
	text-align: center; 

	li {
		max-width: 25%; 
		flex: 0 0 25%;
		padding: 0 20px;

		&:nth-child(n+5) {
			margin-top: 152px; 
		}
	}
	a {
		display: block; 
		transition: transform .3s; 
	}
}

/* Mobile */

@include mobile {
	.list-clients {

		li {
			max-width: 50%; 
			flex: 0 0 50%;

			&:nth-child(n+3) {
				margin-top: 74px; 
			}
		}
		img {
			max-width: 70%; 
			height: auto; 
		}
	}
}

/* ------------------------------------------------------------ *\
	List Contacts
\* ------------------------------------------------------------ */

.list-contacts {
	margin-bottom: 48px; 
	font-size: 16px; 
	line-height: 1.5; 
	font-weight: 500; 
	text-align: center; 
	text-transform: uppercase; 

	li {

		+ li {
			margin-top: 24px; 
		}
	}
	a {

		&[href^="mailto"] {
			color: $orange; 
		}
	}
}

/* Mobile */

@include mobile {
	.list-contacts {
		font-size: 14px; 
	}
}

/* ------------------------------------------------------------ *\
	List Services
\* ------------------------------------------------------------ */

.list-services {
	display: flex; 
	justify-content: space-between; 
	margin-bottom: 45px; 
	text-transform: uppercase; 

	h4 {
		line-height: 1.25; 
		font-weight: 700; 
		letter-spacing: 0.03em; 
	}
	li {
		flex: 0 0 157px;
	}
	figure {
		height: 83px; 
		line-height: 83px; 
		margin-bottom: 31px; 
	}
}

/*  Modifier: List Services Small */

.list-services--small {
	max-width: 550px; 
	margin: 0 auto; 
	font-size: 14px; 
	letter-spacing: 0.05em; 
	text-transform: uppercase; 

	h5 {
		font-weight: 700; 
		letter-spacing: 0.05em; 
		text-transform: uppercase; 
		opacity: .5; 
		transition: opacity .3s; 
	}
	li {
		flex: 0 0 135px;
	}
	figure {
		height: 46px; 
		margin-bottom: 18px; 
		line-height: 46px; 
	}
	i {
		transition: background-image .3s; 
	}
	a {
		display: block;
		transition: color .3s; 
		
		&:focus,
		&:hover {
			opacity: 1; 
			color: $orange; 
			text-decoration: none; 

			h5 {
				opacity: 1; 
			}
		}
	}

	.active {
		color: $orange; 

		h5 {
			opacity: 1; 
		}
	}
}

/* Mobile */

@include mobile {
	.list-services {
		display: block; 
		margin-bottom: 54px; 

		li {

			+ li {
				margin-top: 54px; 
			}
		}
	}

	/*  Modifier: List Services Small */

	.list-services--small {
		position: relative; 
		width: auto; 
		max-width: 200%; 
		padding: 24px 0 8px; 
		margin: 0 -34px 41px; 
		background: $blue; 
		white-space: nowrap; 
		text-align: center; 
		overflow-x: auto; 
		overflow-y: hidden; 

		li {
			display: inline-block; 
			margin: 0 15px; 
			vertical-align: middle; 

			+ li {
				margin: 0; 
				margin: 0 15px; 
			}
		}

		.owl-prev,
		.owl-next {
			position: absolute; 
			width: 16px; 
			height: 28px; 
			top: 50%; 
			margin-top: -14px; 
			background-size: 100% 100%; 
			font-size: 0; 
			line-height: 0; 
			text-indent: -4004px;
		}

		.owl-prev {
			left: 12px; 
			background-image: url(../images/slider-prev.png);
		}
		.owl-next {
			right: 12px; 
			background-image: url(../images/slider-next.png);
		}
	}
}
