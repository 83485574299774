/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	padding-bottom: 82px; 
	margin-bottom: 32px; 
	border-bottom: 1px solid #336981; 

	ul {
		display: flex; 
		justify-content: center; 
		margin: 0; 
		list-style-type: none; 
	}
	li {

		+ li {
			margin-left: 23px; 
		}
	}
	a {
		display: block; 

		&:hover {

			i {
				transform: translateY(-5px); 
			}
		}
	}
	i {
		transition: transform .3s; 
	}
}

/*  Modifier: Socials Alt */

.socials--alt {
	padding-bottom: 0; 
	margin: 0; 
	border: 0; 
	color: $blue; 
	text-align: center; 

	strong {
		display: block; 
		margin-bottom: 13px; 
		font-size: 14px; 
		font-weight: 700; 
		letter-spacing: 0.05em; 
		text-transform: uppercase; 
	}
	li {

		+ li {
			margin-left: 25px; 
		}
	}
}

/* Mobile */

@include mobile {
	.socials {
		padding-bottom: 79px; 
		margin-bottom: 24px; 
	}

	/*  Modifier: Socials Alt */

	.socials--alt {
		padding-bottom: 0; 
	}
}
