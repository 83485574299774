/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

/*  Fields and Textareas */

.field {
	
	textarea,
	input {
		display: block; 
		width: 100%; 
		height: 45px; 
		padding: 0 24px; 
		border: 0; 
		border-radius: 3px; 
		font-size: 14px; 
		color: $black; 
		background: #eee; 

		&::-webkit-input-placeholder { opacity: 1 !important; color: #bcbec0 !important; }
		&::-moz-placeholder { opacity: 1 !important; color: #bcbec0 !important; }
		&:-moz-placeholder { opacity: 1 !important; color: #bcbec0 !important; }
		&:-ms-input-placeholder { opacity: 1 !important; color: #bcbec0 !important; }
	}
}

.field--textarea {

	textarea {
		height: 200px; 
		padding-top: 13px; 
		padding-bottom: 13px; 
		resize: none;
	}
}
