/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { 
	min-width: 320px; 
}

h1 {
	line-height: 1.09; 
}
h2 {
	line-height: 1.14; 
}
h3 {
	line-height: 1.2; 
}
h4 {
	line-height: 1.5; 
}
h5 {
	line-height: 1.43; 
	font-weight: 500; 
	letter-spacing: 2px; 
	text-transform: capitalize; 
}

a {
	color: inherit; 

	&[href^="tel"]:hover {
		color: inherit; 
		text-decoration: none; 
		cursor: default;
	}
	&:focus {
		outline: none;
	}
}

ol,
dl,
ul {
	padding-left: 0; 
}

h1,
h2,
h3 {
	font-weight: 700; 
	letter-spacing: 2px; 
	text-transform: capitalize; 
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0; 
}

/* Tablet Portrait */

@include tablet-portrait {
	h3 {
		font-size: 20px; 
	}
}

/* Mobile */

@include mobile {
	h1 {
		font-size: 35px; 
	}
	h3 {
		font-size: 25px; 
	}
	h4 {
		font-size: 18px; 
	}
}

.main {
  position: relative;
  overflow: hidden;
}