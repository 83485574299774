/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Fullheight  */

.fullheight {
	height: 100vh; 
}

/*  Remove bootstrap mobile backdrop  */

.dropdown-backdrop { 
	display: none; 
}
