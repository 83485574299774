/* ------------------------------------------------------------ *\
	Images
\* ------------------------------------------------------------ */

.images {
	margin: 0; 
	list-style-type: none; 

	&:after {
		@include cf; 
	}

	.image {
		float: left; 
		width: 44.33%; 
	}
	.image--big {
		float: right; 
		width: 55.67%; 
	}
	.image--small {
		width: 27.835%; 
	}
	.image--full {
		width: 100%; 
	}
}

.image {
	
	.image__inner {
		position: relative; 
		display: block; 
		padding-top: 60.12%; 
	}
	figure {
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		background-size: cover; 
		background-position: center center; 
	}
}

/*  Modifier: Image Big */

.image--big {

	.image__inner {
		padding-top: 95.75%; 
	}
}

/*  Modifier: Image Small */

.image--small {

	.image__inner {
		padding-top: 95.8%; 
	}
}

/*  Modifier: Image Full */

.image--full {

	.image__inner {
		padding-top: 43.33%; 
	}
}

/* Mobile */

@include mobile {
	.images {

		.image {
			float: none; 
			width: 100%; 
		}
	}
}
