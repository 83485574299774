/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe {
	position: relative; 
	max-width: 372px; 
	padding: 80px 0 87px; 
	margin: 0 auto; 
	text-align: center; 
	
	ul {
		margin: 0; 
		list-style-type: none; 
	}

	.gform_title {
		color: $blue; 
		text-transform: uppercase; 
		margin: 0 0 10px; 
	}
	.gform_heading {
		margin: 0 -50px 20px; 
	}
	.gform_body {
		max-width: 372px; 
		padding-right: 142px; 
	}
	.gfield_label {
		display: none; 
	}
	.gform_button {
		position: absolute; 
		min-width: 130px; 
		height: 45px; 
		bottom: 87px; 
		right: 0; 
		border: 2px solid #ea622d; 
		border-radius: 3px; 
		font-size: 14px; 
		font-weight: 700;
		letter-spacing: 0.03em;  
		color: #ea622d; 
		text-transform: uppercase; 
		background: none; 
		transition: color .3s, background .3s; 

		&:hover {
			color: $white; 
			background: #ea622d; 
		}
	}
}

/* Mobile */

@include mobile {
	.subscribe {
		padding: 88px 33px 152px;
		font-size: 16px;  
	
		.gform_title {
			line-height: 1; 
			margin-bottom: 4px; 
		}
		.gform_heading {
			margin: 0 0 31px; 
		}
		.gform_button {
			width: calc(100% - 66px); 
			left: 33px; 
			right: 33px; 
			bottom: 90px; 
		}
		.gform_body {
			padding-right: 0; 
		}
	}
}
