/* ------------------------------------------------------------ *\
	Service
\* ------------------------------------------------------------ */

.service {
	padding-bottom: 63px; 
	margin-top: -14px; 
	text-align: center; 

	h3 {
		color: $blue; 
		text-transform: uppercase; 
		margin-bottom: 30px; 
	}

	.service__icon {
		height: 67px; 
		margin-bottom: 32px; 
		line-height: 67px; 
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	.service {
		font-size: 15px; 

		h3 {
			margin-bottom: 10px; 
		}

		.service__icon {
			margin-bottom: 15px; 
		}
	}
}

/* Mobile */

@include mobile {
	.services {
		padding-bottom: 32px; 
		margin-top: -32px; 
	}

	.service {
		font-size: 17px; 

		h3 {
			margin-bottom: 14px; 
		}

		.service__icon {
			height: auto; 
			margin-bottom: 33px; 
			line-height: 1; 
		}
	}
}
