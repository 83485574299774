/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 100px 100px 31px; 
	text-align: center; 
	background: $blue; 

	.footer__logo {
		display: block; 
		width: 114px; 
		height: 62px; 
		margin: 0 auto 63px; 
		vertical-align: middle; 
		transform: translateX(17px); 
		background-image: url(../images/footer-logo.png);
		background-size: 100% 100%; 
		font-size: 0; 
		line-height: 0; 
		text-indent: -4004px;
	}
	.footer__nav {
		font-weight: 500; 
		letter-spacing: 0.05em; 
		color: #bcbec0; 
		text-transform: uppercase;
	  	font-size: 15px;
	  	margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0; 
		}

		ul {
			display: flex; 
			justify-content: center; 
			margin: 0; 
			list-style-type: none; 
		}
		li {

			+ li {
				margin-left: 45px;
			}
		}
		a {
			display: block; 
			transition: color .3s; 

			&:hover {
				color: $white; 
				text-decoration: none; 
			}

			&[href^="tel"] {
				color: $white; 
			}
		}
	}
}

/* Small Desktop */

@include small-desktop {
	.footer { 
		padding: 100px 40px 31px; 
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	.footer {
		
		.footer__nav {
			font-size: 14px; 

			li {

				+ li {
					margin-left: 30px; 
				}
			}
		}
	}
}

/* Mobile */

@include mobile {
	.footer {
		padding: 100px 0 24px; 

		.footer__logo {
			margin-bottom: 74px; 
		}
		.footer__nav {
			margin-bottom: 68px;
			font-size: 16px; 

			ul {
				display: block; 
			}
			li {

				+ li {
					margin: 15px 0 0; 
				}
			}

			&:last-child {
				font-size: 15px; 

				li {

					+ li {
						margin-top: 0; 
					}
				}
			}
		}
	}
}

#copyright {
  position: absolute;
  left: 100px;
  bottom: 32px;
  font-size: 12px;
  color: rgb(188, 190, 192);
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.5px;
}

#blvr {
  position: absolute;
  right: 100px;
  bottom: 32px;
  font-size: 12px;
  color: rgb(188, 190, 192);
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media (max-width: 1024px) {
  #blvr {
	position: static;
	right: auto;
	top: auto;
	margin-top: 10px;
  }

  #copyright {
	margin-top: 10px;
	position: static;
	left: auto;
	top: auto;
  }
}