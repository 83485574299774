/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 91px 0 16px; 

	.section__head {
		margin-bottom: 88px; 
		font-size: 20px; 
		line-height: 1.5; 
		text-align: center; 

		&:only-child {
			margin: 0; 
		}

		h2 {
			color: $blue;
			text-transform: uppercase;

			&:not(:only-child) {
				margin-bottom: 30px; 
			}
		}
	}
	.section__head--alt {
		margin-bottom: 44px; 
	}
	.section__actions {
		position: relative; 
		top: -30px; 
		padding-bottom: 78px; 
		text-align: center; 
		z-index: 2; 
	}
}

/*  Modifier: Section MD */

.section--md {
	padding: 103px 0 137px; 

	.section__head {
		margin-bottom: 117px; 
	}
}

/*  Modifier: Section SM  */

.section--sm {
	padding: 42px 0 81px; 
}

/*  Modifier: Section Pushed */

.section--pushed {
	padding-top: 261px; 
}

/*  Modifier: Section Bordered */

.section--bordered {
	position: relative; 

	&:before {
		content: ''; 
		position: absolute; 
		width: 1030px; 
		height: 50px; 
		bottom: 100%; 
		left: 50%; 
		margin-left: -515px; 
		background: $white; 
		z-index: 2; 
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	/*  Modifier: Section Pushed */

	.section--pushed {
		padding-top: 180px; 
	}
}

/* Mobile */

@include mobile {
	.section {

		.section__head {
			margin-bottom: 80px; 
		}
		.section__actions {
			top: -51px; 
			padding-bottom: 0; 
		}
	}

	/*  Modifier: Section MD */

	.section--md {
		padding-top: 96px; 

		.section__head {
			margin-bottom: 76px; 
		}
	}

	/*  Modifier: Section Bordered */

	.section--bordered {

		&:before {
			display: none; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Video
\* ------------------------------------------------------------ */

.section-video {
	position: relative; 
	height: 650px; 
	font-size: 35px; 
	font-weight: 700; 
	letter-spacing: 0.03em; 
	color: $white; 
	text-transform: uppercase; 
	background-size: cover; 
	background-position: center center;
  	transition: opacity .3s;

	&:hover {
	  opacity: 0.9;
	}
	
	&:before {
		content: ''; 
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		background: rgba($black, .3); 
	}

	a {
		position: absolute; 
		display: block; 
		top: 50%; 
		left: 50%; 
		transform: translate(-50%, -50%); 
		z-index: 2; 
		transition: opacity .3s; 
		
		&:focus {
			color: $white; 
			text-decoration: none; 
		}
		&:hover {
			color: $white; 
			text-decoration: none; 

			i {
				box-shadow: 0 0 0 30px rgba($orange, .4); 
			}
		}
	}
	i {
		position: relative; 
		//top: -3px;
		//margin-left: 24px;
	  	display: block;
	  	margin: 28px auto 0;
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	.section-video {
		height: 400px; 
	}
}

/* Mobile */

@include mobile {
	.section-video {
		height: 500px; 

		a {
			display: block; 
			padding-top: 104px; 
			white-space: nowrap; 
		}
		i {
			position: absolute; 
			top: 0; 
			left: 50%; 
			transform: translateX(-50%); 
			margin: 0; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Alt
\* ------------------------------------------------------------ */

.section-alt {
	position: relative; 
	padding: 198px 0 197px; 
	font-size: 18px; 
	line-height: 1.56; 

	h2 {
		letter-spacing: 0.03em; 
		color: $blue; 
		text-transform: uppercase; 
		margin-bottom: 33px; 
	}

	.section__image {
		position: absolute; 
		height: 100%;
		top: -52px;
		right: 0;
		z-index: 2; 

		img {
			width: auto; 
			height: 100%; 
		}
	}
	.section__image--alt {
		top: 0; 
		left: 0; 
		right: auto; 
	}
}

/* Small Desktop */

@include small-desktop {
	.section-alt {
		padding: 100px 0; 

		.section__image {
			width: 40%; 

			img {
				width: 100%; 
				height: auto; 
			}
		}
	}
}

/* Mobile */

@include mobile {
	.section-alt {
		padding: 0 0 79px; 
		overflow: hidden; 

		.section__image {
			position: static; 
			width: auto; 
			margin: -54px -115px 39px 0; 
		}
		.section__image--alt {
			margin: 0 -33px 9px; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Simple
\* ------------------------------------------------------------ */

.section-simple {
	position: relative; 
	padding: 230px 0 213px;
	font-size: 18px; 
	line-height: 1.56; 
	color: $white; 
	text-align: center; 
	background-size: cover; 
	background-position: center center;

	@media (max-width: 1023px) {
	  padding: 132px 0 109px;
	}

	&:before {
		content: ''; 
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		background: rgba($black, .3); 
	}

	h2 {
		text-transform: uppercase; 
		margin: 0 0 83px; 
	}

	.container {
		position: relative; 
		z-index: 2; 
	}
}

/* Mobile */

@include mobile {
	.section-simple {
		padding: 90px 0 69px; 

		&:before {
			background: -moz-linear-gradient(top, rgba(0,0,0,0.29) 0%, rgba(0,0,0,0.51) 31%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.29) 0%,rgba(0,0,0,0.51) 31%,rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0.29) 0%,rgba(0,0,0,0.51) 31%,rgba(0,0,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a000000', endColorstr='#000000',GradientType=0 );
		}

		h2 {
			margin-bottom: 63px; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Videos
\* ------------------------------------------------------------ */

.section-videos {
	padding-top: 44px; 
	font-size: 18px; 
	line-height: 1.56; 
	color: $white; 
	text-align: center; 

	h2 {
		text-transform: uppercase; 
		margin-bottom: 32px; 
	}
	p {
		margin-bottom: 130px; 
	}

	.section__inner {
		position: relative; 
		padding: 234px 0 44px; 
		background: $black; 

		> * {
			position: relative; 
			z-index: 3; 
		}
	}
	.section__videos {
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		overflow: hidden; 
	}
	.section__video {
		position: absolute; 
		width: 100%; 
		height: 100%; 
		top: 0; 
		left: 0; 
		opacity: 0; 
		visibility: hidden; 
		transition: opacity .3s, visibility .3s; 

		video {
			position: absolute; 
			display: block; 
			min-width: 100%; 
			top: 50%; 
			left: 50%; 
			transform: translate(-50%, -50%); 
		}

		&.active {
			position: static; 
			opacity: 1; 
			visibility: visible; 
		}
	}
}

/* Mobile */

@include mobile {
	.section-videos {
		padding: 0 0 94px; 
		color: inherit; 

		h2 {
			color: $blue; 
		}
		p {
			margin: 0; 
		}
		
		.container {
			display: flex; 
			flex-flow: row wrap; 

			> * {
				flex: 1 0 100%;
			}
		}
		.section__videos {
			position: static; 
			background: $black; 
		}
		.section__video {

			video {
				position: static;
				width: 100%;  
				transform: translate(0, 0); 
			}
		}
		.section__inner {
			padding: 0; 
			background: none; 
		}

		h2,
		p {
			order: 1; 
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Grey
\* ------------------------------------------------------------ */

.section-grey {
	background: #f5f5f5; 
}

.intro-section {
  transform: none !important;
  padding-bottom: 222px;

  .section__image {
	height: auto;
	top: -234px;

	@media (max-width: 1200px) {
	  top: 0;
	  margin-top: 0;
	  margin-left: 0;
	  margin-right: 0;
	}
  }

  @media (max-width: 767px) {
	padding-bottom: 79px;
  }
}

.product-list {

  @media (max-width: 767px) {
	padding: 0 33px;
  }

  a:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	background: rgba($white, .8);
	z-index: 1;
	transition: opacity .3s, visibility .3s;
  }

  a:hover:before {
	opacity: 1;
	visibility: visible;
  }

  > a {
	position: relative;
	display: block;
	background: #f5f5f5;
	text-align: center;
	padding-bottom: 30px;

	&:hover, &:focus {
	  text-decoration: none;
	  outline: 0;

	  .feature__icon {
		transform: scale(1);
		opacity: 1;
		visibility: visible;
	  }
	}

	img {
	  display: block;
	  height: auto;
	  width: 100%;
	}

	h3 {
	  margin-top: 13px;
	  color: #004462;
	  font-size: 22px;
	  padding: 0 15px;
	}

	p {
	  color: #888888;
	  margin: 0;
	  font-size: 14px;
	  padding: 0 15px;
	}

	.feature__icon {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  margin: -40px 0 0 -40px;
	  transform: scale(0.5);
	  opacity: 0;
	  visibility: hidden;
	  z-index: 2;
	  transition: transform .3s, opacity .3s, visibility .3s;
	}
  }

  a + a {
	margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .product-list + .product-list {
	margin-top: 30px;
  }
}

@media (min-width: 991px) {
  .product-list {
	a {
	  margin-top: 30px;
	}
  }
}

@media (max-width: 767px) {
  #intro-section .section__body .container {
	padding: 0;
  }

  .product-list {
	> a {
	  padding-bottom: 20px;

	  h3 {
		font-size: 18px;
	  }
	  p {
		font-size: 15px;
	  }
	}
  }
}

.section-images + .section--bordered {
  border-top: 0 !important;
}

@media (min-width: 768px) {
  body.noscroll {
	overflow: hidden;
  }
}

.products-homepage {
  margin-top: -38px;
}

.post-listings {
  padding-top: 74px;
  border-top: 1px solid #dddddd;

  .post-listing {
	text-align: center;
	margin-bottom: 70px;

	img {
	  display: block;
	  height: auto;
	  width: 100%;
	  margin-bottom: 36px;
	}

	h5 {
	  text-transform: uppercase;
	  letter-spacing: 0;
	  margin-bottom: 30px;
	}

	h3 {
	  color: #004462;
	  text-transform: uppercase;
	  margin-bottom: 30px;

	  a {
		color: #004462 !important;
		text-decoration: none !important;
	  }
	}

	p {
	  margin-bottom: 36px;
	}
  }
}

@media (max-width: 767px) {
  .post-listings {
	.post-listing {
	  margin-bottom: 45px;

	  img {
		margin-bottom: 20px;
	  }

	  h5 {
		margin-bottom: 18px;
	  }

	  h3, p {
		margin-bottom: 15px;
	  }
	}
  }
}