/* ------------------------------------------------------------ *\
	Post
\* ------------------------------------------------------------ */

.posts {
	padding-bottom: 70px; 
	margin-top: -13px; 
	list-style-type: none; 

	.post {

		&:nth-child(even) {
			flex-flow: row-reverse nowrap; 
		}
		
		+ .post {
			margin-top: 50px; 
		}
	}
}

.post {
	display: flex; 
	justify-content: space-between; 
	align-items: center;
	margin: 0; 
	text-align: center; 
	
	h3 {
		color: $blue; 
		text-transform: uppercase; 
		margin-bottom: 27px; 

		a {
		  color: $blue;

		  &:hover {
			color: $blue;
			text-decoration: none;
			}
		}
	}
	h5 {
		letter-spacing: 0.03em; 
		text-transform: uppercase; 
		margin-bottom: 30px; 
	}
	p {
		margin-bottom: 30px; 
	}
	
	.post__btn {
		min-width: 150px; 
	}
	.post__content {
		flex: 0 0 40.17%;
		padding: 0; 
	}
	.post__image {
		flex: 0 0 51.28%;
		padding: 0; 
		
		a {
			display: block; 
			transition: opacity .3s; 

			&:hover {
				opacity: .9; 
			}
		}

		img {
			display: block; 
			width: 100%; 
			height: auto; 
		}
	}

  &:hover {
	.btn--orange-transparent {
	  color: #fff;
	  background: #ea622d;
	}
	.post__image {
	  a {
		opacity: .9;
	  }
	}
  }
}

/* Tablet Portrait */

@include tablet-portrait {
	.post {
		font-size: 15px; 
		
		h3 {
			margin-bottom: 8px; 
		}
		h5 {
			margin-bottom: 10px; 
		}
		p {
			margin-bottom: 15px; 
		}
	}
}

/* Mobile */

@include mobile {
	.posts {
		margin-top: -69px; 

		.post {

			+ .post {
				margin-top: 40px; 
			}
		}
	}

	.post {
		display: block; 
		font-size: 18px; 
		line-height: 1.45; 

		h3 {
			margin: 0 -10px 25px; 
		}
		h5 {
			margin-bottom: 30px; 
		}
		p {
			margin-bottom: 33px; 
		}

		.post__image {
			margin-bottom: 33px; 
		}
	}
}

.blog-nav {
  text-align: center;
}

.white-popup {
  position: relative;
  padding-bottom: 20px;
  width: auto;
  max-width: 900px;
  margin: 20px auto;

  .video-text {
	margin-top: 10px;
	text-align: center;

	.btn {
	  height: 32px;
	  font-size: 14px;
	  line-height: 26px;
	}
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}