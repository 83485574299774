/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	padding: 46px 0 98px; 
	border-bottom: 1px solid #ddd; 
	line-height: 1.65; 
	
	h2 {
		letter-spacing: 0.03em; 
		color: $blue; 
		text-transform: uppercase; 
		margin-bottom: 34px; 
	}
	h4 {
		font-weight: 700; 
		color: $blue; 
		margin-bottom: 29px; 
	}
	h5 {
		letter-spacing: 0.05em; 
		color: $orange; 
		text-transform: uppercase; 
		margin-bottom: 41px; 
	}
	figure {
		display: block; 
		margin: 0 -100px 44px; 

		img {
			display: block; 
			width: 100%; 
			height: auto; 
		}
	}
	p {
		margin-bottom: 41px; 
	}

	.article__head {
		margin-bottom: 72px; 
		font-size: 20px; 
		line-height: 1.5; 
		text-align: center; 

		p {
			margin-bottom: 0; 
		}
	}
	.article__inner {
		max-width: 830px; 
		margin: 0 auto; 
	}
	.article__socials {
		padding-top: 36px; 
	}
}

/* Tablet Portrait */

@include tablet-portrait {
	.article {

		figure {
			margin: 0 0 44px; 
		}
	}
}

/* Mobile */

@include mobile {
	.article {
		padding: 46px 0 60px; 
		
		h2 {
			margin-bottom: 20px; 
			font-size: 25px; 
		}
		h4 {
			margin-bottom: 19px; 
		}
		h5 {
			margin-bottom: 14px; 
		}
		figure {
			margin: 0 -33px 30px; 
		}
		p {
			margin-bottom: 20px; 
		}

		.article__head {
			margin-bottom: 30px; 
		}
		.article__socials {
			padding-top: 20px; 
		}
	}
}

 .fourzerofour {
   max-width: 500px;
   margin: 225px auto 100px;
   text-align: center;
   padding: 0 15px;
 }

@media (max-width: 767px) {
  .fourzerofour {
	margin: 150px auto 0px;
  }
}