/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

.ico-play-big {
	position: relative; 
	display: inline-block; 
	width: 70px; 
	height: 70px; 
	box-shadow: 0 0 0 20px rgba($orange, .4); 
	border-radius: 50%; 
	vertical-align: middle; 
	background: $orange;
	background-clip: padding-box;
	transition: box-shadow .3s; 

	&:before {
		content: ''; 
		position: absolute; 
		width: 0; 
		height: 0; 
		top: 50%; 
		left: 50%; 
		margin: -8px 0 0 -6px; 
		border-width: 8px 0 8px 12px; 
		border-style: solid; 
		border-color: transparent transparent transparent $white; 
	}
}

.ico-circle {
	position: relative; 
	display: inline-block; 
	width: 80px; 
	height: 80px; 
	border: 15px solid rgba($orange, .5); 
	border-radius: 50%; 
	vertical-align: middle; 
	background: $orange; 
	background-clip: padding-box; 

	&:after {
		transform: rotate(90deg); 
	}

	&:before,
	&:after {
		content: ''; 
		position: absolute; 
		width: 10px; 
		height: 2px; 
		top: 50%; 
		left: 50%; 
		margin: -1px 0 0 -5px; 
		background: $white; 
	}
}

.ico-robot {
	display: inline-block; 
	width: 34px; 
	height: 38px; 
	vertical-align: middle; 
	background-image: url(../images/icons/ico-robot.png); 
	background-size: 100% 100%; 
}

.active,
a:hover {

	.ico-robot {
		background-image: url(../images/icons/ico-robot_hover@2x.png); 
	}
}

.ico-drop {
	display: inline-block; 
	width: 38px; 
	height: 40px; 
	vertical-align: middle; 
	background-image: url(../images/icons/ico-drop.png); 
	background-size: 100% 100%; 
}

.active,
a:hover {

	.ico-drop {
		background-image: url(../images/icons/ico-drop_hover@2x.png); 
	}
}

.ico-router {
	display: inline-block; 
	width: 36px; 
	height: 46px; 
	vertical-align: middle; 
	background-image: url(../images/icons/ico-router.png); 
	background-size: 100% 100%; 
}

.active,
a:hover {

	.ico-router {
		background-image: url(../images/icons/ico-router_hover@2x.png); 
	}
}

/* Retina Display */

@include retina {
	.ico-robot {
		background-image: url(../images/icons/ico-robot@2x.png); 
	}

	.ico-drop {
		background-image: url(../images/icons/ico-drop@2x.png); 
	}

	.ico-router {
		background-image: url(../images/icons/ico-router@2x.png); 
	}
}
