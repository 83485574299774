/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$black: #000;
$white: #fff;
$grey: #888;
$orange: #ea622d;
$blue: #004462;

/*  Bootsrap Overrides  */

$font-size-base: 17px;
$line-height-base: 1.59;
$text-color: $grey;
$font-size-h1: 55px;
$font-size-h2: 35px;
$font-size-h3: 25px;
$font-size-h4: 20px;
$font-size-h5: 14px;
$container-large-desktop: (1170px + 30px); // The bootstrap default padding is 30px
$font-family-sans-serif: 'DIN', sans-serif;
