/* ------------------------------------------------------------ *\
	MFP Overrides
\* ------------------------------------------------------------ */

.mfp-bg {
	opacity: .9; 
	background: #093e52; 
}

.mfp-fade {
	opacity: 0; 
	visibility: hidden; 
	transition: opacity .3s, visibility .3s; 

	&.mfp-ready {
		opacity: 1; 
		visibility: visible; 

		&.mfp-bg {
			opacity: .9; 
		}
	}

	&.mfp-removing {
		opacity: 0; 
		visibility: hidden; 

		&.mfp-bg {
			opacity: 0; 
		}
	}
}

.mfp-iframe-holder .mfp-close,
button.mfp-close {
	width: 50px; 
	height: 50px; 
	top: -60px;
	left: 50%;
	margin-left: -25px; 
	opacity: 1; 
	background-image: url(../images/popup-close.png);
	background-size: 100% 100%; 
	transition: opacity .3s; 
	font-size: 0; 
	line-height: 0; 
	text-indent: -4004px;

  	&:active {
	  top: -60px;
	}

	&:hover {
		opacity: .7; 
	}
}
